<!--Vista que muestra sección de términos y condiciones-->
<template>
  <v-container fluid class="pa-0">
    <v-row justify="center" class="mt-5 py-5">
      <v-col cols="10">
        <h1
          class="text-center font-weight-bold"
          :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'"
        >
        POLÍTICAS DE FACTURACIÓN.

        </h1>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <!-- INTRODUCCIÓN -->
      <v-col cols="10" offset="1" md="8" offset-md="2" class="text-justify">  
        <ul>
          <li v-for="(item, i) in politicList" :key="i" class="politic-list">
            {{ item.content }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default{
  data() {
    return {
      politicList: [
          {
            content: "Te recomendamos tener especial cuidado al realizar tus pagos ya que no habrá devoluciones por cargos aplicados y aceptados dentro del proceso normal de compra."
          },
          {
            content: "Deberás de  tener especial cuidado al momento de realizar tu pago ya que genera automáticamente un cargo del 4% de comisión sobre el cargo total que se reciba y que no corresponda a la referencia de pago emitida.        Ya sea por montos mayores, menores o por aquellos generados a líneas de pago vencidas, por montos recibidos de forma directa y que no correspondan a la asociación y por los cuales se solicite devolución correspondiente.."
          },
          {
            content: "Tendrás hasta el final del día de hoy (11:59 pm) para generar tu factura usando el ID de confirmación de la compra que recibirás en el correo electrónico que proporcionaste."
          },
          {
            content: "En caso de requerir factura le recomendamos revisar cuidadosamente los datos fiscales cargados y que sean conforme a su Constancia de Situación Fiscal actualizada"
          },
          {
            content: "AMPI no se responsabiliza por la no emisión de su factura por datos incorrectos cargados que le impidan expedir la misma."
          },
          {
            content: "No se podrán realizar cancelaciones posteriores de facturas emitidas con datos incorrectos por ninguna circunstancia."
          },
          {
            content: "En caso de no requerir factura acepta que en ese momento será generada con RFC genérico, no pudiéndose generar posteriormente por ninguna razón."
          },
          {
            content: "Una vez cerrado el mes, en caso de realizar depósitos a la AMPI, no se podrán realizar facturas correspondientes debiendo ser emitidas en el mismo momento de la operación y en apego a la Resolución Miscelánea Fiscal regla: 2.7.1.21."
          },
          {
            content: "Una vez cerrado el mes, en caso de realizar depósitos a la AMPI, no se podrán hacer devoluciones ni realizar facturas correspondientes en ningún caso, por lo que se recomienda considerar las políticas antes mencionadas."
          }
        ]
    }
  }
}
</script>
<style scoped>
.politic-list{
  margin-bottom: 30px ;
}
</style>